import { Routes, Route, Outlet, Link } from "react-router-dom";

function App() {
  return (
    <div>
      <h1>Man Pages</h1>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="jvn" element={<Jvn />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <nav>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <Link to="/" class="nav-link">
              Home
            </Link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.w3.org/" target="_blank" rel="noreferrer">
              WC3
            </a>
          </li>
        </ul>
      </nav>
      <hr />
      <Outlet />
    </div>
  );
}

function Home() {
  return (
    <div class="pad-list">
      <ul class="list-group">
        <li class="list-group-item">
          <Link to="/jvn">jvn</Link>
        </li>
      </ul>
    </div>
  );
}

function Jvn() {
  return (
  <div class="row">
    <div class="col-2">
      <nav id="navbar-jvn" class="h-100 flex-column align-items-stretch pe-4 border-end">
        <nav class="nav nav-pills flex-column">
          <a class="nav-link" href="#item-usage">Usage</a>
          <a class="nav-link" href="#item-examples">Examples</a>
          <a class="nav-link" href="#item-arguments">Arguments</a>
          <a class="nav-link" href="#item-options">Options</a>
        </nav>
      </nav>
    </div>

    <div class="col-10">
      <h1>jnv - JSON navigator and interactive filter leveraging jq</h1>
      <div data-bs-spy="scroll" data-bs-target="#navbar-jvn" data-bs-smooth-scroll="true" tabindex="0">
        <div id="item-usage">
          <h2>Usage:</h2>
        </div>
        <p class="indent">jnv [OPTIONS] [INPUT]</p>

        <div id="item-examples">
          <h2>Examples:</h2>
        </div>
        <p class="indent">jnv data.json</p>
        <p class="indent">cat data.json | jnv</p>

        <div id="item-arguments">
          <h2>Arguments:</h2>
        </div>
        <p class="indent-bold">[INPUT]</p>
        <p class="double-indent">Optional path to a JSON file. If not provided or if "-" is specified, reads from standard input</p>

        <div id="item-options">
          <h2>Options:</h2>
        </div>
        <p class="indent-bold">-e, --edit-mode &lt;EDIT_MODE&gt;</p>
        <p class="double-indent">Edit mode for the interface ('insert' or 'overwrite'). [default: insert]</p>

        <p class="indent-bold">-i, --indent &lt;INDENT&gt;</p>
        <p class="double-indent">Number of spaces used for indentation in the visualized data. [default: 2]</p>

        <p class="indent-bold">-n, --no-hint</p>
        <p class="double-indent">Disables the display of hints.</p>

        <p class="indent-bold">-d, --expand-depth &lt;JSON_EXPAND_DEPTH&gt;</p>
        <p class="double-indent">Initial depth to which JSON nodes are expanded in the visualization. [default: 3]</p>

        <p class="indent-bold">-s, --limit-length &lt;JSON_LIMIT_LENGTH&gt;</p>
        <p class="double-indent">Limit length of JSON array in the visualization. [default: 50]</p>

        <p class="indent-bold">-l, --suggestion-list-length &lt;SUGGESTION_LIST_LENGTH&gt;</p>
        <p class="double-indent">Number of suggestions visible in the list. [default: 3]</p>

        <p class="indent-bold">-h, --help</p>
        <p class="double-indent">Print help (see more with '--help')</p>

        <p class="indent-bold">-V, --version</p>
        <p class="double-indent">Print version</p>
      </div>
    </div>
  </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;